import React, { FormEvent } from "react";
import { Form, Checkbox } from "semantic-ui-react";
import { Industry } from "../../industries/types";
import { useSelector } from "react-redux";
import { updateShadowingIndustryFilter } from "../actions";
import { RootState } from "../../../app/store/configureStore";
import useItemFilter from "../../../app/hooks/useItemFilter";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface IProps {
  industries: Industry[];
}

const IndustryFilter: React.FC<IProps> = ({ industries }) => {
  const dispatch = useAppDispatch();
  const handleChange = (e: FormEvent<HTMLInputElement>, { value }: any) => {
    dispatch(updateShadowingIndustryFilter(value as number));
  };
  const { t } = useTranslation();
  const { filteredItems: filteredIndustries, renderFilter } = useItemFilter(industries);

  const selectedIds: number[] = useSelector((state: RootState) => state.shadowingsFilter.industryIds);

  return (
    <>
      {renderFilter()}
      <Form className="shadowing-filter__checkbox-group">
        {filteredIndustries.length === 0 ? (
          <span>{t("common.no_records")}</span>
        ) : (
          filteredIndustries.map((industry) => (
            <Form.Field key={industry.id}>
              <Checkbox
                onChange={handleChange}
                key={industry.id}
                value={industry.id}
                checked={selectedIds.includes(industry.id)}
                className="sh-filter"
                label={<label>{industry.name}</label>}
              />
            </Form.Field>
          ))
        )}
      </Form>
    </>
  );
};

export default IndustryFilter;
