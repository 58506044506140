import React, { PropsWithChildren } from "react";
import { Item } from "semantic-ui-react";
import ExcursionItem from "./ExcursionItem";
import { Excursion } from "./types";
import { useTranslation } from "react-i18next";
import Loader from "../../components/Loader";
import useIndustries from "../../app/hooks/useIndustries";

interface Props {
  excursions: Excursion[];
  isLoading: boolean;
  onUpdate?: () => void;
}

const ExcursionList = ({ excursions, isLoading, children, onUpdate }: PropsWithChildren<Props>) => {
  const { t } = useTranslation();
  const { industries: allIndustries } = useIndustries();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {excursions.length > 0 ? (
        <>
          <Item.Group>
            {excursions.map((item) => (
              <ExcursionItem key={item.id} excursion={item} onUpdate={onUpdate} allIndustries={allIndustries} />
            ))}
          </Item.Group>
          {children}
        </>
      ) : (
        t("common.no_records")
      )}
    </>
  );
};

export default ExcursionList;
