import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../../components/page-title/PageTitle";
import { Button, Item, Tab } from "semantic-ui-react";
import Loader from "../../components/Loader";
import ExcursionItem from "./ExcursionItem";
import useMyExcursions from "./useMyExcursions";
import ExcursionCreateModal from "./ExcursionCreateModal";
import useIndustries from "../../app/hooks/useIndustries";

const MyExcursionPage = () => {
  const { t } = useTranslation();
  const [showPrevious, setShowPrevious] = useState(false);
  const { isLoading, excursions, totalCount, renderPagination, reload } = useMyExcursions(showPrevious);
  const { industries: allIndustries } = useIndustries();

  const panes = [
    {
      menuItem: t("my_excursions.tabs.upcoming.tab_title"),
      render: () => (
        <PageTitle
          title={t("my_excursions.tabs.upcoming.title") + (totalCount ? ` (${totalCount})` : "")}
          description={t("my_excursions.tabs.upcoming.description")}
        >
          <ExcursionCreateModal onCreate={() => reload()}>
            <Button primary>{t("excursion.create_excursion")}</Button>
          </ExcursionCreateModal>
        </PageTitle>
      ),
    },
    {
      menuItem: t("my_excursions.tabs.past.tab_title"),
      render: () => (
        <PageTitle
          title={t("my_excursions.tabs.past.title") + (totalCount ? ` (${totalCount})` : "")}
          description={t("my_excursions.tabs.past.description")}
        ></PageTitle>
      ),
    },
  ];

  const handleTabChange = (_: any, { activeIndex }: any) => {
    setShowPrevious(activeIndex === 1);
  };

  return (
    <>
      <Tab
        activeIndex={showPrevious ? 1 : 0}
        onTabChange={handleTabChange}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {excursions.length > 0 ? (
            <>
              <Item.Group>
                {excursions.map((excursion) => (
                  <ExcursionItem
                    key={excursion.id}
                    excursion={excursion}
                    hideLogo
                    onUpdate={reload}
                    allIndustries={allIndustries}
                  />
                ))}
              </Item.Group>
              {renderPagination()}
            </>
          ) : (
            t("common.no_records")
          )}
        </>
      )}
    </>
  );
};

export default MyExcursionPage;
