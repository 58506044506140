import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store/configureStore";
import { Label, Icon } from "semantic-ui-react";
import { CompanyBasic } from "../companies/types";
import {
  updateShadowingCityFilter,
  updateShadowingCompanyFilter,
  updateShadowingDateFilter,
  updateShadowingIndustryFilter,
  updateShadowingOnlineFilter,
} from "../shadowingsFilter/actions";
import { prettifyDateWithoutTime } from "../../app/helpers/dateHelpers";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../app/hooks/useAppDispatch";
import { Industry } from "../industries/types";

interface IProps {
  companies: CompanyBasic[];
  industries: Industry[];
}

const HomePageFilterTags: React.FC<IProps> = ({ companies, industries }) => {
  const { t } = useTranslation();
  const { companyIds, industryIds, dateRange, cities, isOnline } = useSelector(
    (state: RootState) => state.shadowingsFilter
  );

  const dispatch = useAppDispatch();

  const handleCompanyFilterRemove = (_: any, { value }: any) => {
    dispatch(updateShadowingCompanyFilter(value as number));
  };

  const handleIndustryFilterRemove = (_: any, { value }: any) => {
    dispatch(updateShadowingIndustryFilter(value as number));
  };

  const handleCityFilterRemove = (_: any, { value }: any) => {
    dispatch(updateShadowingCityFilter(value as string));
  };

  const handleDateRangeFilterRemove = () => {
    dispatch(updateShadowingDateFilter());
  };

  const handleOnlineRemove = () => {
    dispatch(updateShadowingOnlineFilter(false));
  };

  const companyNameById = (id: number) => companies.find((company) => company.id === id)!.name;
  const industryNameById = (id: number) => industries.find((industry) => industry.id === id)!.name;

  return (
    <Fragment>
      {companies.length === 0 ? null : (
        <div>
          {companyIds.map((companyId) => (
            <Label key={companyId}>
              {companyNameById(companyId)}
              <Icon name="delete" value={companyId} onClick={handleCompanyFilterRemove} />
            </Label>
          ))}
          {industryIds.map((industryId) => (
            <Label key={industryId}>
              {industryNameById(industryId)}
              <Icon name="delete" value={industryId} onClick={handleIndustryFilterRemove} />
            </Label>
          ))}
          {cities.map((city) => (
            <Label key={city}>
              {city}
              <Icon name="delete" value={city} onClick={handleCityFilterRemove} />
            </Label>
          ))}
          {isOnline && (
            <Label>
              {t("shadowings_filter.is_online")}
              <Icon name="delete" onClick={handleOnlineRemove} />
            </Label>
          )}
          {dateRange && (
            <Label>
              {prettifyDateWithoutTime(dateRange.start)} - {prettifyDateWithoutTime(dateRange.end)}
              <Icon name="delete" onClick={handleDateRangeFilterRemove} />
            </Label>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default HomePageFilterTags;
