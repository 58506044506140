import React, { PropsWithChildren } from "react";
import { Message } from "semantic-ui-react";
import ShIcon from "./ShIcon";

interface Props {
  header?: string;
  type: "warning";
}

const Alert = ({ header, children }: PropsWithChildren<Props>) => {
  return (
    <Message warning>
      {header ? (
        <strong className="message-header">
          <ShIcon name="warning" />
          {header}
        </strong>
      ) : (
        <ShIcon name="warning" />
      )}
      {children}
    </Message>
  );
};

export default Alert;
