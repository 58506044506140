import React from "react";
import { Grid } from "semantic-ui-react";
import AppForm from "../../../components/form/Form";
import { BusinessRegistrationCompanyStepFormModel, BusinessRegistrationModel } from "../types";
import useIndustries from "../../../app/hooks/useIndustries";
import useCompanyTypes from "../../../app/hooks/useCompanyType";
import BusinessRegistrationActions from "../BusinessRegistrationActions";
import { useAppForm } from "../../../components/form/useForm";
import { INTEGER_REGEXP } from "../../../app/helpers/regexp";
import { fileSizeValidator, fileTypeValidator } from "../../../app/helpers/validators";

interface Props {
  model: Partial<BusinessRegistrationModel>;
  onSubmit: (m: BusinessRegistrationCompanyStepFormModel) => any;
}

const BusinessRegistrationCompanyStep: React.FC<Props> = ({ model, onSubmit }) => {
  const formHook = useAppForm({ defaultValues: { ...defaultValues, ...model } });
  const { industryOptions } = useIndustries();
  const { companyTypeOptions } = useCompanyTypes();

  return (
    <>
      <AppForm formHook={formHook} onSubmit={onSubmit}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <AppForm.Group name="companyType" label="auth.steps.business.type">
                <AppForm.Select
                  name="companyType"
                  options={companyTypeOptions}
                  rules={{ required: true }}
                  disableAutoSelect
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <AppForm.Group name="name" label="auth.steps.business.name">
                <AppForm.Input
                  name="name"
                  placeholder="auth.steps.business.name_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="code" label="auth.steps.business.code">
                <AppForm.Input
                  name="code"
                  inputMode="numeric"
                  rules={{
                    required: true,
                    pattern: {
                      value: INTEGER_REGEXP,
                      message: "errors.integer",
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <AppForm.Group name="industryId" label="auth.steps.business.industry">
                <AppForm.Select
                  search
                  name="industryId"
                  options={industryOptions}
                  rules={{ required: true }}
                  disableAutoSelect
                />
              </AppForm.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <AppForm.Group name="website" label="auth.steps.business.website">
                <AppForm.Url
                  name="website"
                  placeholder="auth.steps.business.website_placeholder"
                  rules={{ required: true }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <AppForm.Group name="logo">
                <AppForm.File
                  name="logo"
                  accept={[".jpg", ".jpeg", ".png"]}
                  placeholder="auth.steps.business.logo_placeholder"
                  rules={{
                    validate: {
                      filesize: (v) => fileSizeValidator()(v),
                      filetype: (v) => fileTypeValidator(["jpg", "jpeg", "png"])(v),
                    },
                  }}
                />
              </AppForm.Group>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AppForm>
      <BusinessRegistrationActions onSubmit={formHook.handleSubmit(onSubmit)} />
    </>
  );
};

export const defaultValues: BusinessRegistrationCompanyStepFormModel = {
  companyType: null!,
  name: "",
  code: "",
  industryId: null!,
  website: "",
  logo: null!,
};

export default BusinessRegistrationCompanyStep;
