import React, { useEffect, useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Application } from "../types";
import StudentApplicationList from "./StudentApplicationList";
import { ShadowingWithApplications } from "../../shadowings/types";
import { ApplicationStatus } from "../../../app/enums/applicationStatus";
import agent from "../../../app/api/agent";
import useToaster from "../../../app/hooks/useToaster";
import { fetchMyShadowings } from "../../shadowings/actions";
import { useAppDispatch } from "../../../app/hooks/useAppDispatch";

interface IProps {
  shadowing: ShadowingWithApplications;
  approved: boolean;
}

const ApplicationsModal: React.FC<IProps> = ({ shadowing, approved }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { success } = useToaster();
  const dispatch = useAppDispatch();

  const [pendingApplications, setPendingApplications] = useState<Application[]>([]);
  const [approvedApplications, setApprovedApplications] = useState<Application[]>([]);
  const [studentCancelledApplications, setStudentCancelledApplications] = useState<Application[]>([]);

  const handleApplicationConfirmation = (applicationId: number, approved: boolean) => {
    const approvedApplication = pendingApplications.find((x) => x.id === applicationId);
    if (!approvedApplication) return;

    agent.Applications.approve(applicationId, approved).then(() => {
      if (approved) {
        setApprovedApplications([...approvedApplications, approvedApplication]);
        setPendingApplications(pendingApplications.filter((application) => application.id !== applicationId));
        success(t("applications.notifications.application_confirmed"));
        dispatch(fetchMyShadowings({ past: false }, false));
      } else {
        setPendingApplications(pendingApplications.filter((application) => application.id !== applicationId));
        success(t("applications.notifications.application_rejected"));
      }
    });
  };

  const handleApplicationCancellation = (applicationId: number) => {
    agent.Applications.cancel(applicationId).then(() => {
      setApprovedApplications(approvedApplications.filter((application) => application.id !== applicationId));
      success(t("applications.notifications.application_cancelled"));
    });
  };

  const applications = approved
    ? approvedApplications.concat(studentCancelledApplications)
    : pendingApplications.concat(studentCancelledApplications);

  useEffect(() => {
    if (!shadowing.applications) return;

    setPendingApplications(
      shadowing.applications.filter((application) => application.status === ApplicationStatus.WAITING_FOR_APPROVAL)
    );

    setApprovedApplications(
      shadowing.applications.filter((application) => application.status === ApplicationStatus.COMPANY_APPROVED)
    );

    setStudentCancelledApplications(
      shadowing.applications.filter((application) => application.status === ApplicationStatus.STUDENT_CANCELLED)
    );
  }, [shadowing]);

  const renderModalTrigger = () => {
    if (approved) {
      if (approvedApplications.length === 0) return null;

      return (
        <Link to="#">
          {t("common.student_applications")}: {approvedApplications.length}
        </Link>
      );
    } else {
      if (pendingApplications.length === 0) return null;

      return (
        <Button primary>
          {t("applications.company.see_new_applications")} ({pendingApplications.length})
        </Button>
      );
    }
  };

  return (
    <Modal
      dimmer="inverted"
      closeOnEscape={true}
      closeOnDimmerClick={true}
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={renderModalTrigger()}
    >
      <Modal.Header>
        {'"'}
        {shadowing.position}
        {'" '}
        {approved ? t("applications.company.approved_applications") : t("applications.company.new_applications")}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          {applications.length > 0 ? (
            <StudentApplicationList
              applications={applications}
              handleConfirmation={handleApplicationConfirmation}
              handleCancellation={handleApplicationCancellation}
            />
          ) : (
            <span>{t("common.no_students_to_position")}.</span>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions></Modal.Actions>
    </Modal>
  );
};

export default ApplicationsModal;
