import { useEffect, useMemo, useState } from "react";

export type City = string;

const useHardcodedCities = () => {
  const [cities, setCities] = useState<City[]>([]);

  const hardcodedCities = [
    "Akmenės r.",
    "Alytaus m.",
    "Alytaus r.",
    "Anykščių r.",
    "Birštono",
    "Biržų r.",
    "Druskininkų",
    "Elektrėnų",
    "Ignalinos r.",
    "Jonavos r.",
    "Joniškio r.",
    "Jurbarko r.",
    "Kaišiadorių r.",
    "Kalvarijos",
    "Kauno m.",
    "Kauno r.",
    "Kazlų Rūdos",
    "Kėdainių r.",
    "Kelmės r.",
    "Klaipėdos m.",
    "Klaipėdos r.",
    "Kretingos r.",
    "Kupiškio r.",
    "Lazdijų r.",
    "Marijampolės",
    "Mažeikių r.",
    "Molėtų r.",
    "Neringos",
    "Pagėgių",
    "Pakruojo r.",
    "Palangos m.",
    "Panevėžio m.",
    "Panevėžio r.",
    "Pasvalio r.",
    "Plungės r.",
    "Prienų r.",
    "Radviliškio r.",
    "Raseinių r.",
    "Rietavo",
    "Rokiškio r.",
    "Skuodo r.",
    "Šakių r.",
    "Šalčininkų r.",
    "Šiaulių m.",
    "Šiaulių r.",
    "Šilalės r.",
    "Šilutės r.",
    "Širvintų r.",
    "Švenčionių r.",
    "Tauragės r.",
    "Telšių r.",
    "Trakų r.",
    "Ukmergės r.",
    "Utenos r.",
    "Varėnos r.",
    "Vilkaviškio r.",
    "Vilniaus m.",
    "Vilniaus r.",
    "Visagino",
    "Zarasų r.",
  ];

  useEffect(() => {
    setCities(hardcodedCities);
  }, []);

  return useMemo(
    () => ({
      cities,
      cityOptions: cities.map((city) => ({ key: city, value: city, text: city })),
    }),
    [cities]
  );
};

export default useHardcodedCities;
