import React, { Fragment } from "react";
import { Accordion, Divider } from "semantic-ui-react";
import { CompanyBasic } from "../../companies/types";
import SearchFilter from "./SearchFilter";
import CompanyFilter from "./CompanyFilter";
import HasApplicationsFilter from "./HasApplicationsFilter";
import DateFilter from "./DateFilter";
import "../styles.less";
import CityFilter from "./CityFilter";
import OnlineFilter from "./OnlineFilter";
import { Industry } from "../../industries/types";
import IndustryFilter from "./IndustryFilter";

interface IProps {
  companies: CompanyBasic[];
  industries: Industry[];
  cities: string[];
  showHasApplicationsFilter: boolean;
}

const ShadowingFilter: React.FC<IProps> = ({ companies, cities, industries, showHasApplicationsFilter }) => {
  const filters = () => {
    return [
      {
        key: "city",
        title: "Miestas",
        content: {
          content: <CityFilter cities={cities} />,
        },
      },
      {
        key: "online",
        title: "Nuotoliniai susitikimai",
        content: {
          content: <OnlineFilter />,
        },
      },
      {
        key: "company",
        title: "Organizacija",
        content: {
          content: <CompanyFilter companies={companies} />,
        },
      },
      {
        key: "industry",
        title: "Sritis",
        content: {
          content: <IndustryFilter industries={industries} />,
        },
      },
      {
        key: "date",
        title: "Data",
        content: {
          content: <DateFilter />,
        },
      },
    ];
  };

  return (
    <div className="shadowing-filter">
      <SearchFilter />

      <Accordion defaultActiveIndex={[0, 1, 2, 3]} panels={filters()} exclusive={false} fluid />

      {showHasApplicationsFilter && (
        <Fragment>
          <Divider />
          <HasApplicationsFilter />
        </Fragment>
      )}
    </div>
  );
};

export default ShadowingFilter;
