import React, { Fragment } from "react";
import { Grid, Button } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import location from "../../../icons/location.svg";
import person from "../../../icons/person.svg";
import { Application } from "../types";
import GenderImage from "../../../components/GenderImage";
import { ApplicationStatus } from "../../../app/enums/applicationStatus";
import { HasRole } from "../../../app/helpers/userHelpers";
import { UserRoles } from "../../../app/auth/roles";
import agent from "../../../app/api/agent";
import ButtonLink from "../../../components/ButtonLink";
import Multiline from "../../../components/Multiline";

interface IProps {
  application: Application;
  handleConfirmation: (applicationId: number, approved: boolean) => void;
  handleCancellation: (applicationId: number) => void;
}

const StudentApplicationItem: React.FC<IProps> = ({ application, handleConfirmation, handleCancellation }) => {
  const { t } = useTranslation();

  const student = application.student;
  const isSchool = HasRole(UserRoles.school);
  const alreadyApproved = application.status === ApplicationStatus.COMPANY_APPROVED;
  const cancelled = application.status === ApplicationStatus.STUDENT_CANCELLED;

  const downloadCv = async () => {
    const response = await agent.Files.getCv(application.cvAsset);
    const url = window.URL.createObjectURL(response.data);
    const filename = unescape(response.headers["content-disposition"].split("'").pop());
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return (
    <Grid.Row>
      <Grid.Column width={2}>
        <GenderImage gender={student.gender} />
      </Grid.Column>
      <Grid.Column width={14}>
        <div className="name">
          {student.contactInfo.fullName}, {student.grade} {t("common.grade_short")}
        </div>
        <div className="details">
          <span>
            <img src={location} alt="location" />
            {student.school}
          </span>
          <span>
            <img src={person} alt="person" />
            {student.contactInfo.email}
          </span>
          {student.contactInfo.phoneNumber && (
            <span>
              {t("common.phone")}: {student.contactInfo.phoneNumber}
            </span>
          )}
        </div>
        <div className="motivation-letter">
          <Multiline value={application.motivationLetter} />
        </div>
        <div className="student-application-actions">
          {application.linkedinUrl && (
            <a href={application.linkedinUrl} target="blank">
              LinkedIn
            </a>
          )}
          {application.cvAsset && (
            <ButtonLink onClick={downloadCv}>{t("applications.company.actions.download_cv")}</ButtonLink>
          )}
          {isSchool && alreadyApproved && (
            <ButtonLink onClick={() => handleCancellation(application.id)}>
              {t("applications.company.actions.cancel_visit")}
            </ButtonLink>
          )}
        </div>
        <Fragment>
          {cancelled ? (
            <>{t(`applications.status.${application.status}`)}</>
          ) : (
            !alreadyApproved && (
              <>
                <Button primary onClick={() => handleConfirmation(application.id, true)}>
                  {t("applications.company.actions.accept")}
                </Button>
                <Button negative onClick={() => handleConfirmation(application.id, false)}>
                  {t("applications.company.actions.reject")}
                </Button>
              </>
            )
          )}
        </Fragment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default StudentApplicationItem;
