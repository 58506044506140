import React, { PropsWithChildren } from "react";
import { Divider, Grid } from "semantic-ui-react";
import AppForm from "../../components/form/Form";
import { FULLNAME_REGEXP } from "../../app/helpers/regexp";
import { useAppFormContext } from "../../components/form/useAppFormContext";
import useIndustries from "../../app/hooks/useIndustries";
import { createDurationOptions } from "../shadowing/durationOptions";
import { prettifyDateWithoutTime } from "../../app/helpers/dateHelpers";
import useHardcodedCities from "../../app/hooks/useHardcodedCities";

interface Props {
  isEditMode?: boolean;
}

const durationOptions = createDurationOptions(90, 150);

const ExcursionForm = ({ isEditMode }: PropsWithChildren<Props>) => {
  const { watch } = useAppFormContext();
  const { industryOptions } = useIndustries();
  const { cityOptions } = useHardcodedCities();
  const useHqAddress = watch("useHqAddress");
  const isOnline = watch("online");
  const isCreateMode = !isEditMode;
  const titleField = (
    <AppForm.Group name="title" label="excursion.form.title">
      <AppForm.Input name="title" rules={{ required: true }} maxLength={100} />
    </AppForm.Group>
  );
  const descriptionField = (
    <AppForm.Group name="description" label="excursion.form.description">
      <AppForm.TextArea name="description" rules={{ required: true }} maxLength={1000} />
    </AppForm.Group>
  );
  const industryField = (
    <AppForm.Group name="industryIds" label="excursion.form.industry">
      <AppForm.Select
        search
        name="industryIds"
        options={industryOptions}
        rules={{ required: true }}
        multiple
        defaultValue={[]}
      />
    </AppForm.Group>
  );
  const maxParticipantField = (
    <AppForm.Group name="participantsCapacity" label="excursion.form.max_participants_capacity">
      <AppForm.Integer
        name="participantsCapacity"
        maxLength={5}
        rules={{
          required: true,
          min: {
            value: 5,
            message: "excursion.form.errors.min_participants_capacity",
          },
        }}
      />
    </AppForm.Group>
  );
  const dateField = (
    <AppForm.Group name="date" label="excursion.form.date">
      <AppForm.Date
        name="date"
        rules={{
          required: true,
          validate: {
            futureDate: (value: string) => (value < new Date().toISOString() ? "errors.futureDate" : true),
            maxDateRange: (value: string) => {
              const date = prettifyDateWithoutTime(value);
              return date < "2024-11-11" || date > "2024-11-15" ? "shadowing.errors.incorrect_date_range" : true;
            },
          },
        }}
      />
    </AppForm.Group>
  );

  const timeField = (
    <AppForm.Group name="time" label="excursion.form.time">
      <AppForm.Time
        name="time"
        rules={{
          required: true,
        }}
      />
    </AppForm.Group>
  );

  const durationField = (
    <AppForm.Group name="visitDuration" label="excursion.form.visit_duration">
      <AppForm.Select name="visitDuration" options={durationOptions} rules={{ required: true }} />
    </AppForm.Group>
  );
  const onlineField = (
    <AppForm.Group name="online">
      <AppForm.Checkbox name="online" label="excursion.form.online" />
    </AppForm.Group>
  );
  const fullNameField = (
    <AppForm.Group name="responsiblePerson.fullName" label="excursion.form.responsible_person.fullname">
      <AppForm.Input
        name="responsiblePerson.fullName"
        maxLength={50}
        rules={{
          required: true,
          pattern: {
            value: FULLNAME_REGEXP,
            message: "excursion.form.errors.fullname",
          },
        }}
      />
    </AppForm.Group>
  );
  const jobTitleField = (
    <AppForm.Group name="responsiblePerson.jobTitle" label="excursion.form.responsible_person.job_title">
      <AppForm.Input name="responsiblePerson.jobTitle" rules={{ required: true }} maxLength={50} />
    </AppForm.Group>
  );
  const responsibleEmailField = (
    <AppForm.Group name="responsiblePerson.email" label="excursion.form.responsible_person.email">
      <AppForm.Email name="responsiblePerson.email" rules={{ required: true }} maxLength={50} />
    </AppForm.Group>
  );
  const responsiblePhoneField = (
    <AppForm.Group name="responsiblePerson.phoneNumber" label="excursion.form.responsible_person.phone">
      <AppForm.Input name="responsiblePerson.phoneNumber" type="tel" maxLength={16} />
    </AppForm.Group>
  );
  const useHqField = (
    <AppForm.Group name="useHqAddress">
      <AppForm.Checkbox name="useHqAddress" label="excursion.form.use_hq_address" />
    </AppForm.Group>
  );
  const cityField = (
    <AppForm.Group name="city" label="excursion.form.city">
      <AppForm.Select name="city" options={cityOptions} rules={{ required: true }} disableAutoSelect />
    </AppForm.Group>
  );

  const addressField = (
    <AppForm.Group name="address" label="excursion.form.address">
      <AppForm.Input name="address" rules={{ required: true }} maxLength={50} />
    </AppForm.Group>
  );

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column>
          {titleField}
          {descriptionField}
          {industryField}
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={8}>{dateField}</Grid.Column>
        <Grid.Column width={8}>{timeField}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>{durationField}</Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>{maxParticipantField}</Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <Grid.Column width={16}>{onlineField}</Grid.Column>
      </Grid.Row>

      {isCreateMode && (
        <>
          <Divider />

          <Grid.Row>
            <Grid.Column width={8}>{fullNameField}</Grid.Column>
            <Grid.Column width={8}>{jobTitleField}</Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>{responsibleEmailField}</Grid.Column>
            <Grid.Column width={8}>{responsiblePhoneField}</Grid.Column>
          </Grid.Row>
        </>
      )}

      {!isOnline && (
        <>
          <Divider />

          <Grid.Row>
            <Grid.Column width={16}>{useHqField}</Grid.Column>
          </Grid.Row>

          {!useHqAddress && (
            <Grid.Row>
              <Grid.Column width={8}>{cityField}</Grid.Column>
              <Grid.Column width={8}>{addressField}</Grid.Column>
            </Grid.Row>
          )}
        </>
      )}
    </Grid>
  );
};

export default ExcursionForm;
