import React, { useState } from "react";
import { Button, Modal } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import useIsLoggedIn from "../../app/hooks/useIsLoggedIn";
import { NonAuthRoutes } from "../../app/auth/routes";
import "./styles.less";
import { useTranslation } from "react-i18next";

const STORAGE = sessionStorage;
const STORAGE_KEY = "registrationModalClosed";

const RegistrationModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isLoggedIn = useIsLoggedIn();
  const wasClosed = STORAGE.getItem(STORAGE_KEY) === "true";
  const [isOpen, setOpen] = useState(true);
  const renderModal = !isLoggedIn && !wasClosed && isOpen;

  if (!renderModal) return null;

  const close = () => {
    STORAGE.setItem(STORAGE_KEY, "true");
    setOpen(false);
  };

  const handleRegistrationClick = () => {
    close();
    navigate(NonAuthRoutes.registration);
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={null}
      open={true}
      closeIcon={true}
      closeOnDimmerClick={true}
      closeOnEscape={true}
      size="fullscreen"
      onClose={() => close()}
      className="registration-modal-container"
    >
      <div className="registration-modal">
        <img src="/assets/seseliavimo-savaite.jpeg" alt="" className="registration-modal__image" />

        <div className="registration-modal__content">
          <p>
            Kviečiame prisidėti prie jaunosios kartos ugdymo karjerai ir dalyvauti nacionalinėje šešėliavimo savaitėje
            2024 m. lapkričio 11–15 dienomis.
          </p>
          <p>
            Iki <b>rugsėjo 23 d.</b> užregistruokite atviras šešėliavimo pozicijas savo organizacijoje ir suteikite
            jaunajai Lietuvos kartai galimybę dar mokyklos suole pažinti profesinį pasaulį!
          </p>
          <div className="registration-modal__actions">
            <Button primary basic onClick={() => handleRegistrationClick()}>
              {t("auth.registration")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RegistrationModal;
