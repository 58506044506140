export const faqTabs: Array<{
  key: string;
  label: string;
  items: Array<{ title: string; text: string }>;
}> = [
  {
    key: "students",
    label: "Mokinių",
    items: [
      {
        title: "Per kiek laiko mano paskyra bus patvirtinta? Ar mane apie tai informuos?",
        text: "Jūsų paskyra bus patvirtinta per 2 d.d., o apie tai būsite informuoti automatiniu elektroniniu laišku.",
      },
      {
        title: "Kaip vyksta aplikavimo procesas?",
        text: "Prisijungus prie platformos, meniu lauke „Pozicijos“ matysite atviras pozicijas, į kurias galite aplikuoti. Paspaudus mygtuką „aplikuoti“, turėsite užpildyti reikiamą informaciją, parašyti motyvacinį laišką ir viskas! Beliks laukti atsakymo iš jūsų pasirinktos organizacijos – apie tai būsite informuoti automatiniu elektroniniu laišku!",
      },
      {
        title: "Kiek kartų galiu aplikuoti?",
        text: "Aplikuoti galėsite net į 5 skirtingas pozicijas. Prieš aplikuojant, būtinai pasitikrinkite, kurią dieną ir valandą vyks šešėliavimo vizitas.",
      },
      {
        title: "Ką turiu atlikti po šešėliavimo vizito?",
        text: "Prisijungus prie platformos, meniu lauke „Aplikacijos“ pamatysite laukelį „Buvę vizitai ir refleksijos“. Po kiekvieno vizito turėsite užpildyti grįžtamąjį ryšį organizacijai ir pasidalinti savo įspūdžiais bei patarimais! Po šešėliavimo vizito jūsų laukia paskutinis užsiėmimas klasėje, kurio metu aptarsite savo šešėliavimo patirtis.",
      },
    ],
  },
  // { key: "schools", label: "Mokytojų", items: [] },
  {
    key: "companies",
    label: "Organizacijų",
    items: [
      {
        title: "Per kiek laiko mano paskyra bus patvirtinta? Ar mane apie tai informuos?",
        text: "Jūsų paskyra bus patvirtinta per 2 d.d., apie tai būsite informuoti elektroniniu laišku.",
      },
      {
        title: "Kada reikia sukurti atviras šešėliavimo pozicijas?",
        text: "Šešėliavimo pozicijas kviečiame sukurti iki 2024 m. rugsėjo 23 d.",
      },
      {
        title: "Ar yra reikalavimai kuriamai šešėliavimo pozicijai?",
        text: "Šešėliavimo vizito trukmė - 3-8 valandos. Susitikimo tikslas – moksleivius supažindinti su konkrečia darbo pozicija, atliekamų funkcijų specifika. Taip pat susitikimo metu rekomenduojama pristatyti organizacijos veiklą, kultūrą. Atvykusiems mokiniams rekomenduojame paskirti vieną ar daugiau užduočių, kurias atlikę jie galėtų sulaukti jūsų komentarų/patarimų.",
      },
      {
        title: "Ką turiu atlikti po šešėliavimo vizito?",
        text: "Vizitui pasibaigus, mokinys suteiks grįžtamąjį ryšį apie vizitą jūsų organizacijoje. Raginame vizitui pasibaigus dar kartą prisijungti prie platformos ir suteikti grįžtamąjį ryšį dalyvavusiems mokiniams bei įvertinti „JA Šešėliavimo“ programą. Tai galite padaryti savo paskyroje, meniu lauke „Mano pozicijos“, kortelėje „Praėję vizitai ir grįžtamasis ryšys“.",
      },
    ],
  },
];
