import React, { useMemo } from "react";
import { Label } from "semantic-ui-react";
import { Industry } from "./types";
import "./styles.less";

interface Props {
  industryIds: number[];
  allIndustries: Industry[];
}

const IndustryTags = ({ industryIds, allIndustries }: Props) => {
  const industries = useMemo(
    () => industryIds.map((id) => allIndustries.find((x) => x.id === id)).filter((x): x is Industry => !!x),
    [allIndustries, industryIds]
  );

  if (industries.length === 0) {
    return null;
  }

  return (
    <div className="industry-tags">
      {industries.map((industry) => {
        return <Label key={industry.id}>{industry.name}</Label>;
      })}
    </div>
  );
};

export default IndustryTags;
