import React from "react";
import { Guide } from "./models";
import GuideBase from "./components/GuideBase";

const items: Guide[] = [
  {
    title: "Programos įgyvendinimo gairės",
    pdfUrl: "",
    links: [
      {
        title: "Savivaldybės šešėliavimo gidas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/municipality/Savivaldybės šešėliavimo gidas.pdf",
      },
      {
        title: "Mokytojo šešėliavimo gidas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/school/Mokytojo šešėliavimo gidas.pdf",
      },
      {
        title: "Mokytojo vadovas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Mokytojo vadovas.pdf",
      },
    ],
  },
  {
    title: "1 užsiėmimas - pažink save",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/1 uzsiemimas.pdf",
    links: [
      {
        title: "1 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/1 užduotis.pdf",
      },
      {
        title: "2 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/2 užduotis.pdf",
      },
      {
        title: "3 užduotis (pdf)",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/3 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Jūsų gyvenimo aprašymas (CV) ir internetinis profilis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - CV.pdf",
      },
      {
        title: "Dalomoji medžiaga - Karjeros kryptys",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medziaga - Karjeros kryptys.pdf",
      },
      {
        title: "Dalomoji medžiaga - Motyvacinis laiškas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - Motyvacinis laiškas.pdf",
      },
    ],
  },
  {
    title: "2 užsiėmimas - pasiruošk save pristatyti",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/2 uzsiemimas.pdf",
    links: [
      {
        title: "4 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/4 užduotis.docx",
      },
      {
        title: "5 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/5 užduotis.pdf",
      },
      {
        title: "6 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/6 užduotis.pdf",
      },
      {
        title: "7 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/7 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Sėkmingo „šešėliavimo“ žingsniai & Kaip sėkmingai „šešėliauti“",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/municipality/second/Dalomoji medziaga - Sėkmingo „šešėliavimo“ žingsniai & Kaip sėkmingai „šešėliauti“.pdf",
      },
      {
        title: "Dalomoji medžiaga - Šešėliavimo dienos planas",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/municipality/second/Dalomoji medziaga - Šešėliavimo dienos planas.pdf",
      },
    ],
  },
  {
    title: "3 užsiėmimas - reflektuok",
    pdfUrl: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzsiemimai/3 uzsiemimas.pdf",
    links: [
      {
        title: "8 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/8 užduotis.pdf",
      },
      {
        title: "9 užduotis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/uzduotys/9 užduotis.pdf",
      },
      {
        title: "Dalomoji medžiaga - Jūsų gyvenimo aprašymas (CV) ir internetinis profilis",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/dalomoji/Dalomoji medžiaga - CV.pdf",
      },
    ],
  },
  {
    title: "Papildoma informacija",
    pdfUrl: "",
    links: [
      {
        title: "Praktiniai patarimai kaip (ne)rašyti CV",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Praktiniai_patarimai_kaip_(ne)rašyti_CV.pdf",
      },
      {
        title: "Kaip nestresuoti per darbo pokalbį",
        url: "https://ugiprostorage.blob.core.windows.net/shadowing-guides/Kaip_nestresuoti_per_darbo_pokalbį.pdf",
      },
    ],
  },
];

const GuideMunicipalityPage = () => {
  return <GuideBase items={items} />;
};

export default GuideMunicipalityPage;
